import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SitesettingEndpoint, Url } from "../../Type/BaseUrl";

const SitesettingService = createApi({
	reducerPath: "SitesettingService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			siteContent: builder.query({
				query: () => {
					return {
						url: SitesettingEndpoint,
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { useSiteContentQuery } = SitesettingService;
export default SitesettingService;
