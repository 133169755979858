import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AffiliateEndpoint, InterestEndpoint, Url } from "../../Type/BaseUrl";

const AffiliateService = createApi({
	reducerPath: "AffiliateService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			affiliateContent: builder.query({
				query: () => {
					return {
						url: AffiliateEndpoint,
						method: "GET",
					};
				},
			}),
			interestContent: builder.mutation({
				query: (InterestData) => {
					return {
						url: InterestEndpoint,
						method: "Post",
						body: InterestData,
					};
				},
			}),
		};
	},
});
export const { useAffiliateContentQuery, useInterestContentMutation } =
	AffiliateService;
export default AffiliateService;
