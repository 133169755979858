import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ContactEndpoint, Url } from "../../Type/BaseUrl";

const ContactService = createApi({
	reducerPath: "ContactService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			contactContent: builder.mutation({
				query: (ContactData) => {
					return {
						url: ContactEndpoint,
						method: "Post",
						body: ContactData,
					};
				},
			}),
		};
	},
});

export const { useContactContentMutation } = ContactService;
export default ContactService;
