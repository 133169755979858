import { configureStore, ConfigureStore } from "@reduxjs/toolkit";
import AboutService from "./services/AboutService";
import AccessibilityService from "./services/AccessibilityService";
import AffiliateService from "./services/AffiliateService";
import ContactService from "./services/ContactService";
import HomeService from "./services/HomeServices";
import LeaderService from "./services/LeaderService";
import PrivacyService from "./services/PrivacyService";
import Services from "./services/Services";
import SitesettingService from "./services/SitesettingService";
import TermService from "./services/TermService";

const Store = configureStore({
	reducer: {
		[HomeService.reducerPath]: HomeService.reducer,
		[Services.reducerPath]: Services.reducer,
		[LeaderService.reducerPath]: LeaderService.reducer,
		[AboutService.reducerPath]: AboutService.reducer,
		[AffiliateService.reducerPath]: AffiliateService.reducer,
		[PrivacyService.reducerPath]: PrivacyService.reducer,
		[TermService.reducerPath]: TermService.reducer,
		[SitesettingService.reducerPath]: SitesettingService.reducer,
		[AccessibilityService.reducerPath]: AccessibilityService.reducer,
		[ContactService.reducerPath]: ContactService.reducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat([
			HomeService.middleware,
			Services.middleware,
			LeaderService.middleware,
			AboutService.middleware,
			AffiliateService.middleware,
			TermService.middleware,
			PrivacyService.middleware,
			SitesettingService.middleware,
			AccessibilityService.middleware,
			ContactService.middleware,
		]),
});

export default Store;
