import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Line from "../../assets/img/Line.webp";
import { useTermContentQuery } from "../../store/services/TermService";
import Loader from "../../assets/img/Loader.gif"

const Term = () => {
	const { data, isLoading } = useTermContentQuery();
	let response = data?.response?.data;
	return (
		<>
			<Header />

			{isLoading ? (
				<>
					<div className="loader" style={{ textAlign: "center" }}>
						<img src={Loader} alt="" className="img-fluid" />
					</div>
				</>
			) : (
				<>
					{/* Inner banner section  */}
					<section className="aboutus-sec">
						<div className="container">
							<div className="heading-inner-banner">
								<h5>
									HOME /<span>{response?.page_title}</span>
								</h5>
								<h2>{response?.page_title}</h2>
							</div>
						</div>
					</section>
					{/* End of Inner banner section  */}

					{/* How rasani works section  */}
					<section className="how-rasani-sec">
						<div className="container">
							{/* <div className="rasani-head1">
						<div className="line-head">
							<img className="img-fluid" src={Line}></img>
							<h5>LAST UPDATED: APRIL 1,2022</h5>
						</div>
						<h2>Terms Of Service</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="rasani-para1 access-para">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ac
									venenatis purus. Sed vestibulum lorem ut lorem imperdiet
									bibendum. Quisque non dignissim sem. Cras vitae pellentesque
									quam. Nunc tristique semper molestie. Etiam id dolor vehicula,
									pretium ipsum vitae, mollis magna. Etiam porttitor dolor
									elementum magna feugiat dignissim. Fusce eleifend vitae arcu
									non vulputate.
								</p>
							</div>

							<div className="rasani-para1 access-para">
								<p>
									Donec suscipit, tortor ut efficitur rhoncus, quam mauris
									convallis urna, et eleifend justo dui in nibh. Ut dictum vitae
									orci ac mattis. In faucibus purus eros, id ultrices neque
									porta non. In mattis arcu viverra viverra blandit. Quisque
									dapibus elementum ligula, vel consequat lectus pharetra vel.
									Quisque hendrerit neque velit, a tristique lacus commodo sed.
								</p>
							</div>

							<div className="rasani-para1 access-para">
								<p>
									Lorem ipsum dolor sit amet, consectetur adipiscing elit. In ac
									venenatis purus. Sed vestibulum lorem ut lorem imperdiet
									bibendum. Quisque non dignissim sem. Cras vitae pellentesque
									quam. Nunc tristique semper molestie. Etiam id dolor vehicula,
									pretium ipsum vitae, mollis magna. Etiam porttitor dolor
									elementum magna feugiat dignissim. Fusce eleifend vitae arcu
									non vulputate.
								</p>
							</div>

							<div className="rasani-para1 access-para">
								<p>
									Donec suscipit, tortor ut efficitur rhoncus, quam mauris
									convallis urna, et eleifend justo dui in nibh. Ut dictum vitae
									orci ac mattis. In faucibus purus eros, id ultrices neque
									porta non. In mattis arcu viverra viverra blandit. Quisque
									dapibus elementum ligula, vel consequat lectus pharetra vel.
									Quisque hendrerit neque velit, a tristique lacus commodo sed.
								</p>
							</div>
						</div>
					</div> */}
							<div
								dangerouslySetInnerHTML={{
									__html: response?.content,
								}}
							></div>
						</div>
					</section>
					{/* End of How rasani works section  */}
				</>
			)}

			<Footer />
		</>
	);
};

export default Term;
