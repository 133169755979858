import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import affilateimg from "../../assets/img/affilateimg.webp";
import Line from "../../assets/img/Line.webp";
import { Link } from "react-router-dom";
import "../../assets/css/affilate.css";
import Getstarted from "../../Components/Getstarted";
import {
	useAffiliateContentQuery,
	useInterestContentMutation,
} from "../../store/services/AffiliateService";
import Loader from "../../assets/img/Loader.gif";
import { useState } from "react";
import { useForm } from "../../Hooks/Form";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Affilate = () => {
	const { data, isLoading } = useAffiliateContentQuery();
	let affiliateResponse = data?.response?.data;
	const [errors, setErrors] = useState([]);
	// console.log(errors);
	const { state, onChange } = useForm({
		first_name: "",
		last_name: "",
		email: "",
		company_name: "",
		phone_number: "",
		prefered_contact_method: "",
	});

	let {
		first_name,
		last_name,
		email,
		company_name,
		phone_number,
		prefered_contact_method,
	} = state;
	const [userData, response] = useInterestContentMutation();
	// console.log(response);

	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("first_name", first_name);
		FormDatas.append("last_name", last_name);
		FormDatas.append("email", email);
		FormDatas.append("company_name", company_name);
		FormDatas.append("phone_number", phone_number);
		FormDatas.append("prefered_contact_method", prefered_contact_method);
		userData(FormDatas);
	};

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			toast.success(response?.data?.message);
			setErrors([]);
			state.first_name = "";
			state.last_name = "";
			state.email = "";
			state.company_name = "";
			state.phone_number = "";
			state.prefered_contact_method = "";
		}
	}, [response.isSuccess]);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />

			{isLoading ? (
				<>
					<div className="loader" style={{ textAlign: "center" }}>
						<img src={Loader} alt="" className="img-fluid" />
					</div>
				</>
			) : (
				<>
					{/* Inner banner section  */}
					<section className="aboutus-sec">
						<div className="container">
							<div className="heading-inner-banner">
								<h5>
									HOME /<span>{affiliateResponse?.page_title}</span>
								</h5>
								<h2>{affiliateResponse?.page_title}</h2>
							</div>
						</div>
					</section>
					{/* End of Inner banner section  */}

					{/* Our Affilate section  */}
					{/* <section className="Affilate-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="Affilate-img">
                <img className="img-fluid" src={affilateimg}></img>
              </div>
            </div>

            <div className="col-md-6">
              <div className="affilate-text">
                <div className="rasani-head1 for-head-account-flex">
                  <div className="line-head">
                    <img className="img-fluid" src={Line}></img>
                    <h5 className="wow animate__animated animate__bounce">
                      AFFILIATES
                    </h5>
                  </div>
                  <h2 className="wow animate__animated animate__backInRight">
                    Rassani Affiliates
                  </h2>
                </div>
                <p className="wow animate__animated animate__backInRight">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  A arcu cursus vitae congue mauris rhoncus aenean. Hac
                  habitasse platea dictumst vestibulum rhoncus. Dolor magna eget
                  est lorem ipsum dolor sit
                </p>
                <div className="rasani-ul-and-button affilte-ul">
                  <ul>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod{" "}
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod{" "}
                    </li>
                    <li>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
					{/* End of Our Affilate section  */}

					{/* right buyers start */}
					<section>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="right-buyer">
										<p
											dangerouslySetInnerHTML={{
												__html: affiliateResponse?.content,
											}}
										></p>
										{/* <h4>Get ready to sell your leads fast to the right buyers.</h4>
								<p>
									Signing up with Rasani is your first step towards finding the
									right campaigns for your call center, digital marketing
									traffic, and affiliates. Well connect you with buyers, and
									manage the campaign within our platform. Let’s execute a great
									campaign together! For way more information see
									automatenspiele. The first step is understanding what products
									you do best and help you move them. Register today to explore
									current offers or tell us what you have so we can start
									promoting.
								</p>
								<p>
									Mortgage Live Transfers (broken down by loan type eventually,
									right now the simpler the better):
								</p>
								<p>
									We have buyers ready for qualified live transfers. Manage your
									agents to contact, qualify, and correctly transfer and well
									handle the rest! Start dialing today!
								</p>

								<h4>Behalf Campaign/Mortgage:</h4>
								<p>
									We provide client-specific data campaigns to “Call on Behalf”
									of clients we connect you with. Well, provide the data,
									dialer, and campaign to manage it all. This campaign is
									usually easier to produce for and great for beginner agents
								</p> */}
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* right buyers end */}
				</>
			)}

			{/* Create account section  */}
			<section className="create-account-sec affilate-sec">
				<div className="container">
					<div className="row">
						<div className="col-lg-8 col-md-11 mx-auto">
							<div className="create">
								<div className="rasani-head1 for-head-account-flex">
									<div className="line-head">
										<img className="img-fluid" src={Line}></img>
										<h5>AFFILIATES</h5>
									</div>
									<h2>SUBMIT INTEREST FORM</h2>
									<p>
										Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
										do eiusmod
									</p>
								</div>
							</div>

							<form onSubmit={onSubmit}>
								<div className="row">
									<div className="col-md-6">
										<div className="form-group">
											{/* <input type="text" placeholder="Name"></input> */}
											<input
												type="text"
												className={`${errors && errors?.first_name
													? "SetErrorshow form-control"
													: "form-control"
													}`}
												id="inputname"
												placeholder="First Name"
												name="first_name"
												value={first_name}
												onChange={onChange}
											/>
											{errors && errors?.first_name && (
												<span className="errorMessage">
													<p>{errors && errors?.first_name}</p>
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											{/* <input type="email" placeholder="Email Address"></input> */}
											<input
												type="text"
												className={`${errors && errors?.last_name
													? "SetErrorshow form-control"
													: "form-control"
													}`}
												id="inputname"
												placeholder="Last Name"
												name="last_name"
												value={last_name}
												onChange={onChange}
											/>
											{errors && errors?.last_name && (
												<span className="errorMessage">
													<p>{errors && errors?.last_name}</p>
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											{/* <input type="text" placeholder="Company Name"></input> */}
											<input
												type="text"
												className={`${errors && errors?.company_name
													? "SetErrorshow form-control"
													: "form-control"
													}`}
												id="inputname"
												placeholder="Company Name"
												name="company_name"
												value={company_name}
												onChange={onChange}
											/>
											{errors && errors?.company_name && (
												<span className="errorMessage">
													<p>{errors && errors?.company_name}</p>
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											{/* <input type="text" placeholder="Email Address "></input> */}
											<input
												type="text"
												className={`${errors && errors?.email
													? "SetErrorshow form-control"
													: "form-control"
													}`}
												id="inputname"
												placeholder="Email"
												name="email"
												value={email}
												onChange={onChange}
											/>
											{errors && errors?.email && (
												<span className="errorMessage">
													<p>{errors && errors?.email}</p>
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											{/* <input type="text" placeholder="Phone Number"></input> */}
											<input
												type="text"
												className={`${errors && errors?.phone_number
													? "SetErrorshow form-control"
													: "form-control"
													}`}
												id="inputname"
												placeholder="Phone Number"
												name="phone_number"
												value={phone_number}
												onChange={onChange}
											/>
											{errors && errors?.phone_number && (
												<span className="errorMessage">
													<p>{errors && errors?.phone_number}</p>
												</span>
											)}
										</div>
									</div>

									<div className="col-md-6">
										<div className="form-group">
											{/* <input
												type="text"
												placeholder="Prefered Contact Method"
											></input> */}
											<input
												type="text"
												className={`${errors && errors?.prefered_contact_method
													? "SetErrorshow form-control"
													: "form-control"
													}`}
												id="inputname"
												placeholder="Prefered Contact Method"
												name="prefered_contact_method"
												value={prefered_contact_method}
												onChange={onChange}
											/>
											{errors && errors?.prefered_contact_method && (
												<span className="errorMessage">
													<p>{errors && errors?.prefered_contact_method}</p>
												</span>
											)}
										</div>
									</div>

									<div className="col-md-12">
										{response?.isLoading ? <>
											<div className="form-btn">
												<button type="submit">Loading...</button>
											</div></> : <>
											<div className="form-btn">
												<button type="submit">Submit</button>
											</div>
										</>}
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</section>
			{/* End of Create account section  */}

			{/* <Getstarted /> */}

			<Footer />
		</>
	);
};

export default Affilate;
