import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AccessbilityEndpoint, Url } from "../../Type/BaseUrl";

const AccessibilityService = createApi({
    reducerPath: "AccessibilityService",
    baseQuery: fetchBaseQuery({
        baseUrl: Url,
    }),
    endpoints: (builder) => {
        return {
            accessbilityContent: builder.query({
                query: () => {
                    return {
                        url: AccessbilityEndpoint,
                        method: "GET",
                    };
                },
            }),
        };
    },
});
export const { useAccessbilityContentQuery } =
    AccessibilityService;
export default AccessibilityService;
