import React from "react";
import Contact from "../../Components/Contact";
import Footer from "../../Components/Footer";
import Getstarted from "../../Components/Getstarted";
import Header from "../../Components/Header";
import { useLeaderContentQuery } from "../../store/services/LeaderService.js";
import Loader from "../../assets/img/Loader.gif"

const Leaderbuy = () => {
	const { data, isLoading } = useLeaderContentQuery();
	let response = data?.response?.data;
	return (
		<>
			<Header />

			{isLoading ? (
				<>
					<div className="loader" style={{ textAlign: "center" }}>
						<img src={Loader} alt="" className="img-fluid" />
					</div>
				</>
			) : (
				<>
					{/* Inner banner section  */}
					<section className="aboutus-sec">
						<div className="container">
							<div className="heading-inner-banner">
								<h5>
									HOME /<span>{response?.page_title}</span>
								</h5>
								<h2>{response?.page_title}</h2>
							</div>
						</div>
					</section>
					{/* Lead buying begin start */}
					<section>
						<div className="container">
							<div className="row">
								<div className="col-lg-12">
									<div className="lead-buyind-para">
										{/* <h2>
									Lead buying begins with trust and performance. <br></br> Our
									platform offers that and so much more.
								</h2>
								<p>
									Rasani finds you the right leads based on your specific
									business needs. Detail your volume, parameters, and what you
									need to convert and close. Then let us find, manage and
									consult the right campaigns on our custom-built platform.
									Register today and let us know who you are and how we can
									help. Begin exploring our Offers section and feel confident
									you get what pay for.
								</p>
								<h4>Mortgage Live Transfers:</h4>
								<p>
									Making your phones ring! Give us the type of borrower you’re
									looking for and well connect your Loan Officers with them!
									Rasani and its team of affiliates will source the right data
									for your campaign. Agents will call, verify, and qualify the
									homeowner before sending them over.
								</p>
								<h4>Behalf Campaign/Mortgage:</h4>
								<p>
									Having a hard time monetizing your data and motivating loan
									officers to cold call? Hand over your internal marketing
									efforts to us. We are experts in training agents specifically
									for you! Let us “Call on Behalf” of your bank with your data
									and let us help you generate more business!
								</p>
								<h4>RTP/Mortgage:</h4>
								<p>
									Real-Time Post Leads is a great way to give your team double
									verified homeowners who have been qualified and “warmed up”
									for a callback from an expert on lending. We specify what time
									of day to reach out to the borrower as well as providing all
									the details needed to engage and close them.
								</p> */}
										<p
											dangerouslySetInnerHTML={{
												__html: response?.content,
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
					</section>
					{/* Lead buying begin end */}</>
			)}

			{/* End of Inner banner section  */}
			<Contact />

			{/* <Getstarted /> */}

			<Footer />
		</>
	);
};

export default Leaderbuy;
