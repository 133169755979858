import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { AboutEndpoint, Url } from "../../Type/BaseUrl";

const AboutService = createApi({
	reducerPath: "AboutService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			aboutContent: builder.query({
				query: () => {
					return {
						url: AboutEndpoint,
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { useAboutContentQuery } = AboutService;
export default AboutService;
