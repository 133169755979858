import * as React from "react";
import PublicRoutes from "../src/routes/Publicroutes";
import "bootstrap/dist/css/bootstrap.min.css";
import Style from "./assets/css/style.css";
const App = () => {
  return (
    <div>
      <PublicRoutes />
    </div>
  );
};

export default App;
