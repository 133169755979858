import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Line from "../../assets/img/Line.webp";
import market from "../../assets/img/market.webp";
import "../../assets/css/aboutus.css";
import { Link } from "react-router-dom";
import { useAboutContentQuery } from "../../store/services/AboutService";

const Aboutus = () => {
	const { data, isLoading } = useAboutContentQuery();
	let response = data?.response?.data;
	return (
		<>
			<Header />
			{/* Inner banner section  */}
			<section className="aboutus-sec">
				<div className="container">
					<div className="heading-inner-banner">
						<h5>
							HOME /<span>ABOUT US</span>
						</h5>
						<h2>About Us</h2>
					</div>
				</div>
			</section>

			{/* End of Inner banner section  */}

			{/* How rasani works section  */}
			{/* <section className="how-rasani-sec">
				<div className="container">
					<div className="rasani-head1">
						<div className="line-head">
							<img className="img-fluid" src={Line}></img>
							<h5 className="wow animate__animated animate__bounce">
								WHAT WE DO
							</h5>
						</div>
						<h2 className="wow animate__animated animate__backInLeft">
							How Rasani Consulting Works
						</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="rasani-para1">
								<p className="wow animate__animated animate__backInLeft">
									We are community. We are changing the experience linked to
									lead buying and selling. Here we house everything from product
									to platform to the purchase of lead gen services. We
									orchestrate the details to simply deliver your needs as easy
									and effective as possible.
								</p>

								<p className="wow animate__animated animate__backInLeft">
									We understand relationships and have built a relationship
									platform. Whether a buyer or a vendor your business needs are
									important and our team works hard to find and manage the right
									relationships for you. Our lead dashboard helps keep your
									campaigns organized. Our team consists of experts in lead gen
									to understand what makes sense and is good for business. We
									own and operate our own call centers, landing pages, and lead
									sources understanding from start to finish the work Vendors
									put in.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			<div
				dangerouslySetInnerHTML={{
					__html: response?.content,
				}}
			></div>
			{/* End of How rasani works section  */}

			{/* Consulting service seciton  */}
			<section className="consulting-sec">
				<div className="container">
					<div className="rasani-head1">
						<div className="line-head">
							<img className="img-fluid" src={Line}></img>
							<h5 className="wow animate__animated animate__bounce">
								WHAT WE DO
							</h5>
						</div>
						<h2 className="wow animate__animated animate__backInLeft">
							Consulting Services
						</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="rasani-para1">
								<p className="wow animate__animated animate__backInLeft">
									You can’t do it alone. Test out your joy for automatenspiele.
									Whether you’re just starting out or you’re a seasoned veteran.
									Explore what you can outsource and allow us to consult here.
									We provide our own custom-built platform to manage it all.
									While our team of experts’ hands-on guide you in all your
									marketing needs. Here is the essential list of services we
									provide to help you convert, close and conquer.
								</p>
							</div>

							<div className="rasani-ul-and-button">
								<ul>
									<li>Quality Control</li>
									<li>Dialer Services</li>
									<li>Account Management</li>
									<li>Data Management</li>
									<li>Reporting Tools</li>
									<li>Recruiting and Training</li>
								</ul>

								<div className="rasani-button">
									<Link to="/affilate">Submit Interest</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
			{/* End of Consulting service section  */}

			{/* Market place section  */}
			{/* <section className="market-place-sec">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 backgrounddg">
              <div className="rasani-head">
                <div className="line-head">
                  <img className="img-fluid" src={Line}></img>
                  <h5 className="wow animate__animated animate__bounce">
                    WHAT WE DO
                  </h5>
                </div>
                <h2 className="wow animate__animated animate__backInLeft">
                  Coming Soon: The Rasani Marketplace
                </h2>
              </div>
              <div className="rasani-para">
                <p className="wow animate__animated animate__backInLeft">
                  We are changing the experience linked to lead buying and
                  selling. Here we house everything from product to platform to
                  the purchase of lead gen services. We orchestrate the details
                  to simply deliver your needs as easy and effective as
                  possible. Welcome to the in one platform for Buyers and
                  Sellers.
                </p>
              </div>
              <div className="market-img">
                <img className="img-fluid" src={market}></img>
              </div>
            </div>
          </div>
        </div>
      </section> */}
			{/* End of Market place section  */}

			<Footer />
		</>
	);
};

export default Aboutus;
