import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.svg";
import { useSiteContentQuery } from "../store/services/SitesettingService";
let imageUrl = "https://admin.rasaniconsulting.com/uploads/";

const Footer = () => {
	const { data, isLoading } = useSiteContentQuery();
	let response = data?.response?.data;
	// console.log(response?.copyright);
	return (
		<>
			{/*// < Footer start here > */}
			<footer id="footerdown">
				<div className="footerlink">
					<div className="container-fluid">
						<div className="footer-sec">
							<div className="row align-items-center">
								<div className="col-lg-1"></div>
								<div className="col-lg-3 col-md-2 col-sm-2">
									<div className="logo-mainfooter">
										<figure>
											<Link to="/">
												<img src={`${imageUrl}${response?.footer_logo}`}></img>
											</Link>
										</figure>
									</div>
								</div>
								<div className="col-lg-3 col-md-5 col-sm-5">
									<div className="socialitemsfooter">
										<ul>
											<li>
												{" "}
												<a target="_blank" href={`${response?.facebook}`}>
													<i className="fa fa-facebook" aria-hidden="true"></i>
												</a>
											</li>

											<li>
												{" "}
												<a target="_blank" href={`${response?.twitter}`}>
													<i className="fa fa-twitter" aria-hidden="true"></i>
												</a>
											</li>
											<li>
												{" "}
												<a target="_blank" href={`${response?.youtube}`}>
													<i
														className="fa fa-youtube-play"
														aria-hidden="true"
													></i>
												</a>
											</li>
											<li>
												{" "}
												<a target="_blank" href={`${response?.instagram}`}>
													<i className="fa fa-instagram" aria-hidden="true"></i>
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-lg-5 col-md-5 col-sm-5">
									<div className="footerlinkend">
										<ul className="footerul">
											<li>
												<Link to="/accesibility">ACCESSIBILITY</Link>
											</li>
											<li>
												<Link to="/termofuse">TERMS OF USE</Link>
											</li>
											<li>
												<Link to="/privacy">PRIVACY POLICY</Link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="copyright">
						<div className="row">
							<div className="col-lg-12">
								<div className="copycontent">
									<p>© {response && response.copyright}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};

export default Footer;
