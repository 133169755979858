import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Line from "../../assets/img/Line.webp";
import { Link } from "react-router-dom";
import "../../assets/css/createaccount.css";

const Createaccount = () => {
  return (
    <>
      <Header />
      {/* Inner banner section  */}
      <section className="aboutus-sec">
        <div className="container">
          <div className="heading-inner-banner">
            <h5>
              HOME /<span>CREATE ACCOUNT</span>
            </h5>
            <h2>Create Account</h2>
          </div>
        </div>
      </section>

      {/* End of Inner banner section  */}

      {/* Create account section  */}
      <section className="create-account-sec">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="create">
                <div className="rasani-head1 for-head-account-flex">
                  <div className="line-head">
                    <img className="img-fluid" src={Line}></img>
                    <h5>THE MARKETPLACE</h5>
                  </div>
                  <h2>CREATE YOUR ACCOUNT</h2>
                  <p>
                    FPO Area for Contact Form Code Here. Sample Form below for
                    CSS/Style Only.
                  </p>
                </div>

                <div className="create-text">
                  <p>
                    Already have an account?{" "}
                    <Link to="Createaccount.js">login here</Link>
                  </p>
                </div>
              </div>

              <form>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" placeholder="Name"></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="email" placeholder="Email Address"></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" placeholder="Company Name"></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" placeholder="Email Address "></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input type="text" placeholder="Phone Number"></input>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="form-group">
                      <input
                        type="text"
                        placeholder="Prefered Contact Method"
                      ></input>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-btn1">
                      <button className="submit">Submit & Continue</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      {/* End of Create account section  */}

      {/* craete para section  */}
      <section className="create-account-para">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="create-all-para">
                <p>*Indicates a required field.</p>
                <p>
                  We require 2-factor authentication to create your account. You
                  will be prompted to enter a code
                </p>
                <p> to continue continuing your creating your account.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* End of create para section  */}

      <Footer />
    </>
  );
};

export default Createaccount;
