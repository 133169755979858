import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Contact from "../Components/Contact";
import Aboutus from "../Pages/About/Aboutus";
import Affilate from "../Pages/Affilate/Affilate";
import Accessibility from "../Pages/Assebility/Accessibility";
import Createaccount from "../Pages/Createaccount/Createaccount";
import Home from "../Pages/Home/Home";
import Leaderbuy from "../Pages/Leaderbuy/Leaderbuy";
import Privacy from "../Pages/Privacy/Privacy";
import Services from "../Pages/Services/Services";
import Term from "../Pages/termofuse/termofuse";

const PublicRoutes = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Home />} />
				<Route path="/aboutus" element={<Aboutus />} />
				<Route path="/services" element={<Services />} />
				<Route path="/accesibility" element={<Accessibility />} />
				<Route path="/termofuse" element={<Term />} />
				<Route path="/createaccount" element={<Createaccount />} />
				<Route path="/privacy" element={<Privacy />} />
				<Route path="/affilate" element={<Affilate />} />
				<Route path="/leaderbuy" element={<Leaderbuy />} />
				<Route path="/contact" element={<Contact />} />
			</Routes>
		</BrowserRouter>
	);
};

export default PublicRoutes;
