import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { TermEndpoint, Url } from "../../Type/BaseUrl";

const TermService = createApi({
	reducerPath: "TermService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			termContent: builder.query({
				query: () => {
					return {
						url: TermEndpoint,
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { useTermContentQuery } = TermService;
export default TermService;
