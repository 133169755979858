import React from "react";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import Line from "../../assets/img/Line.webp";
import phone2 from "../../assets/img/phone2.webp";
import monitor2 from "../../assets/img/monitor2.webp";
import chart2 from "../../assets/img/chart2.webp";
import market from "../../assets/img/market.webp";
import "../../assets/css/services.css";
import { useServiceContentQuery } from "../../store/services/Services";
import { useEffect } from "react";
let imageUrl = "https://admin.rasaniconsulting.com/uploads/";

const Services = () => {
	const { data: serviceData, isLoading: serviceLoading } =
		useServiceContentQuery();
	let serviceResponse = serviceData?.response?.data;
	// console.log(serviceResponse);
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Header />
			{/* Inner banner section  */}
			<section className="aboutus-sec">
				<div className="container">
					<div className="heading-inner-banner">
						<h5>
							HOME /<span>SERVICES</span>
						</h5>
						<h2>Services</h2>
					</div>
				</div>
			</section>
			{/* End of Inner banner section  */}

			{/* How rasani works section  */}
			{serviceResponse?.map((data, index) => {
				return (
					<section className="how-rasani-sec service-sec" key={data?.id}>
						<div className="container">
							<div className="rasani-head1">
								<div className="line-head">
									<img className="img-fluid" src={Line}></img>
									<h5 className="wow animate__animated animate__bounce">
										SERVICES
									</h5>
								</div>
								<h2>
									{data?.title}
									<span className="phone2">
										<img
											className="img-fluid"
											src={`${imageUrl}${data?.icon}`}
										></img>
									</span>
								</h2>
							</div>
							<div className="row">
								<div className="col-md-12">
									<div className="rasani-para1 service-para">
										<p
											dangerouslySetInnerHTML={{
												__html: data?.description,
											}}
										></p>
									</div>
								</div>
							</div>
						</div>
					</section>
				);
			})}
			{/* End of How rasani works section  */}

			{/* How rasani works section  */}
			{/* <section className="how-rasani-sec service-sec">
				<div className="container">
					<div className="rasani-head1">
						<div className="line-head">
							<img className="img-fluid" src={Line}></img>
							<h5 className="wow animate__animated animate__bounce">
								SERVICES
							</h5>
						</div>
						<h2>
							Digital Leads{" "}
							<span className="phone2">
								<img className="img-fluid" src={monitor2}></img>
							</span>{" "}
						</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="rasani-para1 service-para">
								<p>
									Our Digital Lead Product Offers High Connectivity To Verified
									Homeowners! <br></br> Another way to increase sales team
									productivity and consistently obtain a higher than usual
									closure rates due to the high quality of the data provided.
									Digital Leads Are Designed To Be Cost-Friendly For Our End
									Users. Great For Small And Large Organizations Looking To Dial
									Outbound.
								</p>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* End of How rasani works section  */}

			{/* How rasani works section  */}
			{/* <section className="how-rasani-sec service-sec">
				<div className="container">
					<div className="rasani-head1">
						<div className="line-head">
							<img className="img-fluid" src={Line}></img>
							<h5 className="wow animate__animated animate__bounce">
								SERVICES
							</h5>
						</div>
						<h2>
							Data Analytics{" "}
							<span className="phone2">
								<img className="img-fluid" src={chart2}></img>
							</span>{" "}
						</h2>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="rasani-para1 service-para">
								<p>
									Rasani generates over 500 transfers daily. Our aggregated data
									from those transfers is Valuable! Allow us to build specific
									data list that meet your company’s needs.
								</p>
								<ul>
									<li>
										{" "}
										<span> Aged Refi Data </span>{" "}
									</li>
									<li>
										{" "}
										<span> Voice Verified Data </span>
									</li>
									<li>
										{" "}
										<span> Aged Live Transfers </span>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</section> */}
			{/* End of How rasani works section  */}

			{/* Market place section  */}
			{/* <section className="market-place-sec">
        <div className="containe-fluid">
          <div className="row">
            <div className="col-md-8 backgrounddg">
              <div className="rasani-head">
                <div className="line-head">
                  <img className="img-fluid" src={Line}></img>
                  <h5 className="wow animate__animated animate__bounce">
                    WHAT WE DO
                  </h5>
                </div>
                <h2 className="wow animate__animated animate__backInLeft">
                  Coming Soon: The Rasani Marketplace
                </h2>
              </div>
              <div className="rasani-para">
                <p className="wow animate__animated animate__backInLeft">
                  We are changing the experience linked to lead buying and
                  selling. Here we house everything from product to platform to
                  the purchase of lead gen services. We orchestrate the details
                  to simply deliver your needs as easy and effective as
                  possible. Welcome to the in one platform for Buyers and
                  Sellers.
                </p>
              </div>
              <div className="market-img">
                <img className="img-fluid" src={market}></img>
              </div>
            </div>
          </div>
        </div>
      </section> */}
			{/* End of Market place section  */}

			<Footer />
		</>
	);
};

export default Services;
