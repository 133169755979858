import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HomeEndpoint, Url } from "../../Type/BaseUrl";

const HomeService = createApi({
	reducerPath: "HomeService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			homeContent: builder.query({
				query: () => {
					return {
						url: HomeEndpoint,
						method: "Get",
					};
				},
			}),
		};
	},
});

export const { useHomeContentQuery } = HomeService;
export default HomeService;
