import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { PrivacyEndpoint, Url } from "../../Type/BaseUrl";

const PrivacyService = createApi({
	reducerPath: "PrivacyService",
	baseQuery: fetchBaseQuery({
		baseUrl: Url,
	}),
	endpoints: (builder) => {
		return {
			privacyContent: builder.query({
				query: () => {
					return {
						url: PrivacyEndpoint,
						method: "GET",
					};
				},
			}),
		};
	},
});
export const { usePrivacyContentQuery } = PrivacyService;
export default PrivacyService;
