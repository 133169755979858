import React from "react";
import { Link } from "react-router-dom";
import rasanifooter from "../../src/assets/img/rasanifooter.webp";
import Line from "../../src/assets/img/Line.webp";
import { useSiteContentQuery } from "../store/services/SitesettingService";
import { useForm } from "../Hooks/Form";
import { useContactContentMutation } from "../store/services/ContactService";
import { useState } from "react";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Contact = () => {
	const { data, isLoading } = useSiteContentQuery();
	let Socialresponse = data?.response?.data;
	const [errors, setErrors] = useState([]);
	// console.log(errors);
	const { state, onChange } = useForm({
		name: "",
		email: "",
		subject: "",
		message: "",
	});

	let { name, email, subject, message } = state;
	const [userContact, response] = useContactContentMutation();
	// console.log(response);

	const onSubmit = (e) => {
		e.preventDefault();
		const FormDatas = new FormData();
		FormDatas.append("name", name);
		FormDatas.append("email", email);
		FormDatas.append("subject", subject);
		FormDatas.append("message", message);
		userContact(FormDatas);
	};

	useEffect(() => {
		if (response.isError) {
			setErrors(response?.error?.data?.errors);
		}
	}, [response?.error?.data]);

	useEffect(() => {
		if (response.isSuccess) {
			toast.success(response?.data?.message);
			setErrors([]);
			state.name = "";
			state.email = "";
			state.subject = "";
			state.message = "";
		}
	}, [response.isSuccess]);
	return (
		<>
			<div className="contectus">
				<div className="socialitems">
					<div className="container">
						<div className="row">
							<div className="col-lg-1 col-1"></div>
							<div className="col-lg-7 col-7 p-0 backgroundcolor">
								<div className="para-social">
									<p>
										Do you have a similar project? Call Us{" "}
										{Socialresponse && Socialresponse?.contact_phone}
									</p>
								</div>
							</div>
							<div className="col-lg-4 col-4 p-0 backgroundlr ">
								<div className="socialitems1">
									<ul>
										<li>
											<a target="_blank" href={`${Socialresponse?.facebook}`}>
												<i className="fa fa-facebook" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a target="_blank" href={`${Socialresponse?.instagram}`}>
												<i className="fa fa-instagram" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a target="_blank" href={`${Socialresponse?.twitter}`}>
												<i className="fa fa-twitter" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a target="_blank" href="/">
												<i className="fa fa-pinterest-p" aria-hidden="true"></i>
											</a>
										</li>
										<li>
											<a target="_blank" href={`${Socialresponse?.youtube}`}>
												<i
													className="fa fa-youtube-play"
													aria-hidden="true"
												></i>
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="lastfooter">
					<div className="container-fluid">
						<div className="row">
							<div className="col-lg-6 p-0">
								<div className="img-footer">
									<figure>
										<img src={rasanifooter} className="img-fluid"></img>
									</figure>
								</div>
							</div>
							<div className="col-lg-5 col-12 ">
								<form onSubmit={onSubmit}>
									<div className="footer-form">
										<div className="why-content">
											<img className="line" src={Line}></img>
											<h6>Why Rasani ?</h6>
										</div>
										<div className="heading-input">
											<h2>Feel Free To Connect With Us</h2>
											<div className="row">
												<div className="col-lg-6">
													<div className="fromcontorl">
														<input
															type="text"
															className={`${errors && errors?.name
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															id="inputname"
															placeholder="Name"
															name="name"
															value={name}
															onChange={onChange}
														/>
													</div>
													{errors && errors?.name && (
														<span className="errorMessage">
															<p>{errors && errors?.name}</p>
														</span>
													)}
												</div>
												<div className="col-lg-6">
													<div className="fromcontorl">
														<input
															type="text"
															className={`${errors && errors?.email
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															id="inputname"
															placeholder="Email"
															name="email"
															value={email}
															onChange={onChange}
														/>
													</div>
													{errors && errors?.email && (
														<span className="errorMessage">
															<p>{errors && errors?.email}</p>
														</span>
													)}
												</div>
											</div>
											<div className="row">
												<div className="col-lg-12">
													<div className="fromcontorl">
														<input
															type="text"
															className={`${errors && errors?.subject
																? "SetErrorshow form-control"
																: "form-control"
																}`}
															id="inputname"
															placeholder="Subject"
															name="subject"
															value={subject}
															onChange={onChange}
														/>
													</div>
													{errors && errors?.subject && (
														<span className="errorMessage">
															<p>{errors && errors?.subject}</p>
														</span>
													)}
												</div>
											</div>
											<div className="row">
												<div className="col-lg-12">
													<div className="texterea">
														<div className="form-group fromcontorl">
															<textarea
																className={`${errors && errors?.message
																	? "SetErrorshow form-control"
																	: "form-control"
																	}`}
																id="exampleFormControlTextarea1"
																placeholder="Message"
																name="message"
																value={message}
																onChange={onChange}
															></textarea>
														</div>
														{errors && errors?.message && (
															<span className="errorMessage">
																<p>{errors && errors?.message}</p>
															</span>
														)}
													</div>
												</div>
											</div>
										</div>
										<div className="footer-content-btn">
											<div className="contect">
												{/* <h4>Contact Us</h4>
												<p>{Socialresponse && Socialresponse?.contact_phone}</p> */}
												{/* <span>
													<Link to="/privacy">Privacy Policy</Link>
												</span> */}
											</div>
											<div className="getstartbtn">
												{response.isLoading ? <>
													<button type="submit" className="btn">
														Loding...
													</button>
												</> : <>
													<button type="submit" className="btn">
														Submit
													</button>
												</>}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Contact;
